<template>
  <div class="content">
    <base-header class="pb-7">
      <div class="row align-items-center py-4 d-md-none">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">统计表详情</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--7">
      <div>
        <card refs="l1" class="no-border-card" footer-classes="pb-2" v-loading="loading">
          <div class="row">
              <div class="col">
                <h3>{{title}} <span class="text-muted" v-if="data.entry.status>=0" :class="data.entry.status===2?'text-danger':''">[{{ statusName(data.entry.status) }}]</span></h3>
              </div>
          </div>
          <div class="row mb-3" v-if="data.entry.status === 2">
            <div class="col">
              <el-alert type="warning" show-icon :title="'审核意见：'+data.entry.remark">
              </el-alert>
            </div>
          </div>
          <div class="row mb-3" v-if="data.entry.status === 1 && data.entry.modify_reason && data.entry.remark">
            <div class="col">
              <el-alert type="warning" show-icon :title="'您的退回申请被驳回。'+data.entry.remark">
              </el-alert>
            </div>
          </div>
          <div class="row">
              <div class="col">
                <el-tabs type="card">
                    <el-tab-pane label="统计日期">
                      <el-form ref="form" :model="data.entry" :show-message="false" label-width="auto" label-position="top" :disabled="!editable">
                        <div class="row">
                          <div class="col">
                            <label>统计年份</label>
                            <el-form-item prop="year" :rules="[{required:true}]" class="mr-2">
                                <el-select v-model="data.entry.year" placeholder="选择年份">
                                    <el-option v-for="y in years" :key="y" :label="y" :value="y"></el-option>
                                </el-select>
                            </el-form-item>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <label>日期范围</label>
                            <el-form-item prop="date_range" :rules="[{required:true}]">
                                <el-date-picker
                                    v-model="data.entry.date_range"
                                    type="daterange"
                                    range-separator="-"
                                    format="yyyy-MM-dd"
                                    value-format="yyyy-MM-dd"
                                    :picker-options="pickerOptions"
                                    :disabled="!data.entry.year"
                                    start-placeholder="起始日期"
                                    end-placeholder="结束日期">
                                </el-date-picker>
                            </el-form-item>
                          </div>
                        </div>
                      </el-form>
                    </el-tab-pane>
                    <el-tab-pane label="表格">
                      <div class="d-flex justify-content-between" v-if="editable">
                        <span></span>
                        <el-button type="success" plain class="mb-3" @click="openImport">重新导入表格</el-button>
                      </div>
                      <el-table refs="datarows" row-key="id" 
                          header-row-class-name="thead-light"
                          header-cell-class-name="text-center"
                          style="width: 100%" max-height="500"
                          :data="data.rows" stripe>
                          <el-table-column prop="product_name" label="产品名称" min-width="160" class-name="pl-2">
                          </el-table-column>
                          <el-table-column label="产地">
                              <el-table-column prop="province" label="省份" min-width="110"></el-table-column>
                              <el-table-column prop="place" label="市、区、县" min-width="120"></el-table-column>
                          </el-table-column>
                          <el-table-column prop="amount" :label="this.dataTypeName(this.data.entry.data_type)+'金额(万元)'" min-width="90">
                              <template slot-scope="{row}">
                                  {{ row.amount.toFixed(2) }}
                              </template>
                          </el-table-column>
                          <el-table-column prop="remark" label="备注">
                              <template slot-scope="{row}">
                                  <small>{{ row.remark }}</small>
                              </template>
                          </el-table-column>
                      </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="附件">
                      <h5>
                        销售（采购）凭证主要是指在销售（采购）过程中产生的发票、合同或其它有效凭证以及盖章的《统计表》。
                      </h5>
                      <div class="mb-3" v-if="editable"><small class="text-muted ls-2">请上传JPG/PNG图片，大小不能超过4MB.</small></div>
                      <el-upload action="/backend/api/uploadimage"
                        name="image"
                        list-type="picture"
                        :file-list="fileList"
                        :with-credentials="true"
                        :limit="10"
                        multiple
                        accept=".jpg, .png"
                        :on-success="uploadSuccess"
                        :on-remove="removeFile"
                        :on-error="uploadError"
                        :on-progress="progress" :disabled="!editable">
                        <el-button type="success" icon="el-icon-plus" v-if="editable" plain>
                          <span v-if="data.entry.data_type===0">上传采购凭证</span>
                          <span v-else>上传销售凭证</span>
                        </el-button>
                      </el-upload>
                    </el-tab-pane>
                </el-tabs>
              </div>
          </div>
          <div class="row mt-4">
            <div class="col">
              <el-button type="text" @click="back2list"><i class="el-icon-back"></i> 返回</el-button>
              <el-button type="primary" v-if="editable" @click="save" :loading="loading" :disabled="uploading">{{ this.loading ? "正在保存..." : "保存" }}</el-button>
              <el-button type="success" v-if="editable" @click="submit" :loading="loading" :disabled="uploading">{{ this.loading ? "提交中..." : "提交给统计中心" }}</el-button>
              <el-button type="default" v-if="!isSuper && data.entry.status === 1" @click="modify" :disabled="data.entry.modify_reason && data.entry.remark">申请退回</el-button>
            </div>
          </div>
        </card>
      </div>
    </div>
    <el-drawer :visible="importing" :append-to-body="true" :modal-append-to-body="true" :before-close="beforeCloseImport"
      direction="ltr" size="50%"
      :title="data.entry.data_type === 0 ? '导入《广州国有企业采购帮扶产品数据统计表》':'导入《广州国有企业销售帮扶产品数据统计表》'">
        <div class="px-4">
          <import-form v-if="importing" :data-type="data.entry.data_type" :entry="data.entry" @saved="successImport" @cancelled="cancelImport"></import-form>
        </div>
    </el-drawer>
  </div>
</template>
<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";

export default {
  components: {
    RouteBreadCrumb,
    'import-form': () => import('./Import.vue')
  },
  data () {
    return {
      backendHost: process.env.VUE_APP_BACKEND_TARGET || 'https://guoqi.ycycsw.cn',
      loading: false,
      uploading: false,
      importing: false,
      years: [],
      pickerOptions: {
        disabledDate: (d) => {
          if (!this.data.entry.year) {
            return d.getFullYear() == new Date.getFullYear()
          }
          return d.getFullYear() != this.data.entry.year
        }
      },
      data: {
        entry: {
          year: '',
          date_range: [],
          start_date: '',
          end_date: '',
        },
        rows:[],
        attachments: []
      }
    }
  },
  computed: {
      dataId () {
        return this.$route.params.id
      },
      title () {
        if (this.data.entry) {
          return `${this.dataTypeName(this.data.entry.data_type)}统计表（${this.toDateString(this.data.entry.start_date)}~${this.toDateString(this.data.entry.end_date)}）`
        }
        return ''
      },
      fileList () {
        return this.data.attachments.map(x => {
          return { name: x.origin_file_name, url: this.backendHost + x.file_name }
        })
      },
      isSuper () {
        if (this.$store.state.user.user) return this.$store.state.user.user.is_super
        else return null
      },
      editable () {
        return !this.isSuper && this.data.entry.status !== 0 && this.data.entry.status !== 1 && this.data.entry.status !== 3
      }
  },
  watch: {
    'data.entry.date_range' (v) {
      this.data.entry.start_date = v[0]
      this.data.entry.end_date = v[1]
    }
  },
  methods: {
    dataTypeName (data_type) {
      switch (data_type) {
        case 0: return '采购';
        case 1: return '销售';
        default: return ''
      }
    },
    statusName (status) {
      switch (status) {
        case 0: return '审核中'
        case 1: return '审核通过'
        case 2: return '审核未通过'
        case 3: return '申请退回中'
        default: return ''
      }
    },
    fetchData() {
      this.loading = true;
      this.axios.get("datainputs/" + this.dataId)
      .then((res) => {
        if (res.data.errcode == 0) {
            this.data = res.data.data
            this.data.entry.date_range = [
              this.toDateString(this.data.entry.start_date),
              this.toDateString(this.data.entry.end_date)
            ]
        }
        else {
            this.$notifyErr('未能成功加载数据', res.data.errmsg);
        }
        this.loading = false;
      })
      .catch((e) => {
        console.error(e);
        this.loading = false;
      })
    },
    openImport() {
      this.importing = true
    },
    successImport () {
        this.fetchData()
        this.importing = false
    },
    cancelImport () {
        this.importing = false
    },
    beforeCloseImport (done) {
      this.cancelImport()
      done()
    },
    progress () {
      this.uploading = true
    },
    uploadSuccess (rep, file) {
      const newFile = { data_input_id: this.data.entry.id, file_name: rep.url, origin_file_name: file.raw.name, file_type: file.raw.type }
      try {
        this.data.attachments.push(newFile)
      } catch (e) {
        console.error(e.message)
      }
      this.uploading = false
    },
    uploadError () {
      this.uploading = false
      this.$notifyErr('上传失败', '确保文件大小及格式符合要求.')
    },
    removeFile (file) {
      for(var i = 0; i < this.data.attachments.length; i++) {
        if (file.url.indexOf(this.data.attachments[i].file_name)>0) {
          this.data.attachments.splice(i, 1)
          break
        }
      }
    },
    save () {
      this.update(-1)
    },
    submit () {
      this.$confirm('数据一旦提交便不能进行修改，统计中心工作人员将于2个工作日内对数据进行符合性审查。是否继续？', '提示', {
        confirmButtonText: '提交',
        confirmButtonClass: 'el-button--success',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.update(0)
      })
    },
    update (status) {
      this.loading = true;
      this.data.entry.status = status
      this.axios.put("datainputs/" + this.dataId, this.data)
      .then((res) => {
        if (res.data.errcode == 0) {
          if (status === -1)
            this.$notifyOK('保存成功')
          else {
            this.$notifyOK('数据已提交')
            this.back2list()
          }
        }
        else {
            this.$notifyErr('操作失败', res.data.errmsg);
        }
        this.loading = false;
      })
      .catch((e) => {
        this.$notifyErr('操作失败', e.message);
        this.loading = false;
      })
    },
    modify () {
      this.$prompt('请输入退回申请原因', '退回申请', {
        confirmButtonText: '提交',
        cancelButtonText: '取消'
      }).then(({ value }) => {
        this.axios.post("datainputs/modify", { id: this.data.entry.id, reason:  value }).then((res) => {
          if (res.data.errcode == 0) {
            this.data.entry.status = 3
            this.$notifyOK('操作成功', '统计中心已收到退回申请, 请等待处理.')
          }
          else {
              this.$notifyErr('操作失败', res.data.errmsg);
          }
          this.loading = false;
        })
        .catch((e) => {
          this.$notifyErr('操作失败', e.message);
          this.loading = false;
        })
      })
    },
    back2list () {
      this.$router.back()
    }
  },
  mounted () {
    var curYear = new Date().getFullYear()
    for(var y = curYear - 3; y <= curYear + 1; y++) {
        this.years.push(y)
    }
    this.fetchData()
  }
};
</script>